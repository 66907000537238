import { ComponentType, useContext, useMemo, useState } from 'react'
import Stack from '@mui/material/Stack'
import { useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Collapse } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import Alert from '@mui/material/Alert'

import { Box, Button, Checkbox, Divider, LoadingDocument, Text } from 'src/components'
import { TableContext, TableProvider } from 'src/providers/table-provider'
import { ChevronDown } from 'src/assets/icons'

import { RouteId } from '../../constants/routes'

import Questionaire from './questionaire-logic/questionaire-container'
import { TableMenu } from './table-menu'
import { CoreTable } from './core-table'

enum TableTabs {
	Create = 0,
	Logic = 1,
}

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

const a11yProps = (index: number) => {
	return {
		id: `cango-table-tab-${index}`,
		'aria-controls': `cango-table-tabpanel-${index}`,
	}
}

const CustomTabPanel: ComponentType<TabPanelProps> = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, display: 'flex', height: 700, flex: 1 }}>{children}</Box>
			)}
		</div>
	)
}

const TablesContent: ComponentType<{
	isMenuCollapsed: boolean
	onMenuToggleClick: () => void
	menuWidth: number
}> = ({ isMenuCollapsed, onMenuToggleClick, menuWidth }) => {
	const { table, isLoadingTable, tableList, updateTableConfig } = useContext(TableContext)
	const [isSavingQuestionaire, setIsSavingQuestionaire] = useState(false)
	const [activeTab, setActiveTab] = useState<number>(0)

	const hasOptionsColumn = useMemo(() => {
		if (!table?.fields.length) {
			return false
		}
		return table.fields.some((field) => field.type === TableTypes.FieldType.OPTIONS)
	}, [table?.fields])

	const handleClickQuestionaire = async (isChecked: boolean) => {
		setIsSavingQuestionaire(true)
		await updateTableConfig({ is_questionaire: isChecked }, { updateDb: true })
		setIsSavingQuestionaire(false)
	}

	if (isLoadingTable) {
		return (
			<Box flex={1}>
				<LoadingDocument errorText="" returnToRoute={RouteId.Tables} docType="database" />
			</Box>
		)
	}

	if (!table?._id) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" mt={10} flex={1}>
				<Text variant="h4" color={'grey'}>
					{tableList.length > 0 ? 'Select a table' : 'Create a table to get started'}
				</Text>
			</Box>
		)
	}

	return (
		<Box
			width={`calc(100% - ${!isMenuCollapsed ? `${menuWidth + 16}px` : '16px'})`}
			height="calc(100vh - 150px)"
			pt={1}
			pl={isMenuCollapsed ? 2 : 0}
		>
			<Stack direction="row" spacing={2} mb={1}>
				<Button
					variant="text"
					size="small"
					startIcon={
						<ChevronDown style={{ transform: `rotate(${isMenuCollapsed ? '270' : '90'}deg)` }} />
					}
					sx={{ minWidth: 75 }}
					onClick={onMenuToggleClick}
				>
					{isMenuCollapsed ? 'Show ' : 'Hide '}menu
				</Button>
				<Button
					startIcon={<Checkbox size="small" checked={!!table.is_questionaire} />}
					variant="outlined"
					size="small"
					onClick={() => handleClickQuestionaire(!table.is_questionaire)}
					isLoading={isSavingQuestionaire}
					sx={{ width: 250 }}
				>
					This database is a questionaire
				</Button>
			</Stack>
			<div>
				<Collapse in={!!table?.is_questionaire}>
					<Tabs value={activeTab} onChange={(event, newIndex) => setActiveTab(newIndex)}>
						<Tab label="Create" {...a11yProps(TableTabs.Create)} />
						<Tab label="Logic" {...a11yProps(TableTabs.Logic)} />
					</Tabs>
					{!!table?.is_questionaire && !hasOptionsColumn && (
						<Alert severity="warning" sx={{ mt: 1 }}>
							{'You need an "Options" column to use the questionaire feature'}
						</Alert>
					)}
				</Collapse>
			</div>
			<CustomTabPanel index={TableTabs.Create} value={activeTab}>
				<CoreTable rowReordering maxHeight="calc(100vh - 150px)" />
			</CustomTabPanel>
			<CustomTabPanel index={TableTabs.Logic} value={activeTab}>
				<Questionaire />
			</CustomTabPanel>
		</Box>
	)
}

export const TablesContainer: ComponentType = () => {
	const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
	const [columnWidth, setColumnWidth] = useState(350)
	const { tableId: tableIdParam } = useParams<{
		tableId?: string
	}>()

	const handleMouseMove = (e: MouseEvent) => {
		setColumnWidth(e.clientX)
	}

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}

	const handleMouseDown = () => {
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	return (
		<TableProvider tableId={tableIdParam}>
			<Box display="flex" mr={2} flex={1}>
				{!isMenuCollapsed && <TableMenu columnWidth={columnWidth} />}
				{!isMenuCollapsed && (
					<Divider
						orientation="vertical"
						flexItem
						sx={{ borderRightWidth: 4, mr: 2, cursor: 'col-resize' }}
						onMouseDown={handleMouseDown}
					/>
				)}
				<TablesContent
					isMenuCollapsed={isMenuCollapsed}
					onMenuToggleClick={() => {
						setIsMenuCollapsed(!isMenuCollapsed)
					}}
					menuWidth={columnWidth}
				/>
			</Box>
		</TableProvider>
	)
}

export default TablesContainer
